<template>
  <div>
    <b-container>
      <h3 class="SectionTitle">
        {{gallery_title}}
      </h3>
      <b-carousel
        id="carousel-1"
        :interval="4000"
        img-width="1024"
        img-height="350"
        class="desktop"
      >
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(0, 3)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer  size="xl">
                <!-- <b-img class="modelImg" :src="slide.count.url"></b-img> -->
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <!-- Text slides with image -->
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(0, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(3, 6)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(3, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(6, 9)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(6, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(9, 12)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(9, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(12, 15)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(12, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(15, 18)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(0, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(19, 22)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer size="xl">
                <b-carousel
                  id="carousel-2"
                  :interval="4000"
                  controls
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide
                    img-src="https://picsum.photos/1024/480/?image=52"
                    v-for="(slide, index) in objSplit.slice(0, 21)"
                    :key="index"
                     img-blank
                  >
                    <b-card
                      :img-src="slide.count.url"
                      img-alt="Image"
                      img-top
                      class="mb-2 inside"
                    >
                      <b-card-text class="captionUpdate">
                        {{slide.count.caption}}
                      </b-card-text>
                    </b-card>
                  </b-carousel-slide>
                </b-carousel>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide>
        <!-- <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
          img-blank
        >
          <b-row>
            <b-col
              v-for="(slide, index) in objSplit.slice(15, 16)"
              :key="index"
              class="nopad"
            >
              <b-card
                @click="$bvModal.show(slide.word)"
                :img-src="slide.count.url"
                img-alt="Image"
                img-top
                class="mb-2"
              >
              </b-card>
              <b-modal :id="slide.word" hide-header centered hide-footer>
                <b-img class="modelImg" :src="slide.count.url"></b-img>
              </b-modal>
            </b-col>
          </b-row>
        </b-carousel-slide> -->
      </b-carousel>
      <b-carousel
        id="carousel-1"
        :interval="4000"
        controls
        background="transparent"
        img-width="1024"
        
        class="mobile"
      >
        <b-carousel-slide
          :img-src="slide.count.url"
          
          v-for="(slide, index) in objSplit"
          :key="index"
        >
        </b-carousel-slide>
      </b-carousel>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "gallery",
  props: [
    'gallery_title'
  ],
  data() {
    return {
      gallery: [],
    };
  },
  computed: {
    objSplit() {
      var array = this.gallery;
      var result = Object.keys(array).map((e) => ({
        word: e,
        count: array[e],
      }));
      return result;
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/AlbumGallery?_embed"+
                "?randomadd=" +
                new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.gallery = response.data[0].acf;
      });
  },
};
</script>

<style scoped>
.SectionTitle {
  font-size: 23px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 25px;
  padding-left: 8px;
}
.SectionTitle_Mob {
  font-size: 19px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 25px;
}
.nopad {
  padding: 5px;
}
.card-body {
  /* display: none; */
}
p.card-text.captionUpdate {
  color: #707070;
  font-size: 16px;
  font-weight: 300;
  text-shadow: 0;
  text-shadow: 0 0 BLACK;
  display: none;
}
::v-deep .carousel-caption {
  right: 2%;
  left: 2%;
}
.card-img-top {
  border-radius: 10px;
  object-fit: cover;
  object-position: top center;
  height: 100%;
  width: 100%;
}
.title {
  color: #133f7b;
}
.card {
  height: 300px;
  width: 100%;
  background-color: transparent;
  border: none;
}
.modelImg {
  position: absolute;
  margin: -75% -50%;
  width: 200%;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .card {
  height: 260px;
}
}


@media only screen and (max-width: 990px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  ::v-deep .img-fluid{
    border-radius: 10px;
  }
  .title{
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .carousel-caption {
    bottom: -120px;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .carousel-caption {
    bottom: -190px;
  }
  ::v-deep .carousel-item {
    position: relative;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
    padding-left: 5px;
    padding-right: 5px;
  }
}
::v-deep .card.mb-2.inside {
  height: auto;
  margin-bottom: 17em !important;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 800px!important;
}
::v-deep #carousel-2___BV_inner_ {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 800px!important;
}
::v-deep .carousel-control-prev-icon {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/03/Icon-awesome-chevron-down-1.png);
  height: 15px;
  width: 10px;
  margin-top: 0px;
}
::v-deep .carousel-control-next-icon {
  background-image: url(https://assets.asiantour.com/asian-tour/2024/03/Icon-awesome-chevron-down.png);
  height: 15px;
  width: 10px;
  margin-top: 0px;
}
::v-deep .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0a4080;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}
::v-deep .carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto!important;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
::v-deep .carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto!important;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
::v-deep a.carousel-control-next {
  width: auto!important;
}
</style>
