<template>
  <div class="home">
    <div class="adHeightHome">
      <AdBanner :page="wpHome" />
    </div>
    <!-- Post and Widget Section -->
    <div class="container Post_WidgetSection TestNew">
      <template v-if='isMobile(true)'>
        <b-row>
          <b-col sm="12">
            <template v-if="currentReport != undefined">
              <template v-if="currentReport.includes('tmentry')">
                <NextTournament 
                  :season="this.season"
                  :config="config"
                  :data="data"
                  :Newreport="Newreport"
                  :Year="season"
                  :courseCode="course"
                  :title="title"
                  :currentReport="currentReport"
                  :multi_tourns="multi_tourns"
                  :pastT="pastT"
                  :past_tourns="past_tourns"
                  :head="head"
                  :tm='tm'
                  :tm_params="tm_params"
                  :course="course"
                  :live_scoring="live_scoring"
                  :match="match"
                  :Stableford="Stableford"
                  :reports="reports"
                  :nextRound='nextRound'
                  :roundBtns="roundBtns"
                  :home="homepage"
                  :develop="develop"
                />
              </template>
              <template v-else>
                <UpComing 
                  :data="data"
                  :Newreport="Newreport"
                  :Year="season"
                  :courseCode="course"
                  :title="title"
                  :currentReport="currentReport"
                  :config="config"
                  :multi_tourns="multi_tourns"
                  :pastT="pastT"
                  :past_tourns="past_tourns"
                  :head="head"
                  :tm='tm'
                  :tm_params="tm_params"
                  :course="course"
                  :live_scoring="live_scoring"
                  :match="match"
                  :Stableford="Stableford"
                  :reports="reports"
                  :nextRound='nextRound'
                  :roundBtns="roundBtns"
                />
              </template>
            </template>
            <template v-else>
              <UpComing 
                :data="data"
                :Newreport="Newreport"
                :Year="season"
                :courseCode="course"
                :title="title"
                :currentReport="currentReport"
                :config="config"
                :multi_tourns="multi_tourns"
                :pastT="pastT"
                :past_tourns="past_tourns"
                :head="head"
                :tm='tm'
                :tm_params="tm_params"
                :course="course"
                :live_scoring="live_scoring"
                :match="match"
                :Stableford="Stableford"
                :reports="reports"
                :nextRound='nextRound'
                :roundBtns="roundBtns"
              />
            </template>
          </b-col>
          <b-col sm="12">
            <Slider
              :develop="develop"
              :posts="SliderPost"
              :Year="this.season"
              :config="config"
            />
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <template v-if="tm_params.reports == ''">
          <b-row>
            <b-col lg='12' sm="12">
              <Slider
                :develop="develop"
                :posts="SliderPost"
                :Year="this.season"
                :config="config"
              />
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row>
            <b-col lg='8' sm="12">
              <Slider
                :develop="develop"
                :posts="SliderPost"
                :Year="this.season"
                :config="config"
              />
            </b-col>
            <b-col lg='4' sm="12">
              <!-- <template v-if="tm_params.days_away < '-7' || tm_params.reports == ''">
                <template v-if="course == 'ISQA' ">
                  <SmallOomRight
                    :develop="develop"
                    :oom="oom"
                    :season="this.season"
                    :home="homepage"
                    :key="componentKey"
                    :config="config"
                  />
                </template>
                <template v-else>
                  <NextTournament 
                    :season="this.season"
                    :config="config"
                    :reports="tm_params.reports"
                  />
                </template>
              </template> -->
              <template>
                <template v-if="currentReport.includes('tmentry')">
                  <NextTournament 
                    :season="this.season"
                    :config="config"
                    :data="data"
                    :Newreport="Newreport"
                    :Year="season"
                    :courseCode="course"
                    :title="title"
                    :currentReport="currentReport"
                    :multi_tourns="multi_tourns"
                    :pastT="pastT"
                    :past_tourns="past_tourns"
                    :head="head"
                    :tm='tm'
                    :tm_params="tm_params"
                    :course="course"
                    :live_scoring="live_scoring"
                    :match="match"
                    :Stableford="Stableford"
                    :reports="reports"
                    :nextRound='nextRound'
                    :roundBtns="roundBtns"
                    :home="homepage"
                    :develop="develop"
                  />
                </template>
                <template v-else>
                  <UpComing 
                    :data="data"
                    :Newreport="Newreport"
                    :Year="season"
                    :courseCode="course"
                    :title="title"
                    :currentReport="currentReport"
                    :config="config"
                    :multi_tourns="multi_tourns"
                    :pastT="pastT"
                    :past_tourns="past_tourns"
                    :head="head"
                    :tm='tm'
                    :tm_params="tm_params"
                    :course="course"
                    :live_scoring="live_scoring"
                    :match="match"
                    :Stableford="Stableford"
                    :reports="reports"
                    :nextRound='nextRound'
                    :roundBtns="roundBtns"
                  />
                </template>
              </template>
            </b-col>
          </b-row>
        </template>
      </template>
    </div>

    <!-- Latest News Section -->

    <div id="feature" class="mobileMarginFeatured">
      <h2 class="SectionTitle container hidelatestNews">Latest News</h2>
      <Post :posts="posts" :develop="develop" :config="config" />
      <b-button class="homeButton NewsBTN" to="/news">See All News</b-button>
    </div>


    <!-- Review Section -->
    <div class="reviewSection container">
      <b-row class="reviewBlock">
        <b-col col lg="6" sm="12" class="firstReview">
          <!-- <b-link :href="homeData.acf.left_review_url" > -->
              <img class="imageReview" :src="homeData.acf.left_review_image" v-b-modal.modal-prevent-closing>
          <!-- </b-link> -->
        </b-col>
        <b-col col lg="6" sm="12">
          <b-link :href="homeData.acf.right_review_url" target="_blank">
              <img class="imageReview" :src="homeData.acf.right_review_image">
          </b-link>
        </b-col>
      </b-row>
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Submit Your Name"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Name"
            label-for="name-input"
            invalid-feedback="Name is required"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email-input"
            invalid-feedback="Email is required"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              v-model="email"
              :state="emailState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <!-- Gallery Section -->

    <div class=" GreyBack">
      <!-- <h2 class="SectionTitle container">Latest Images</h2> -->
      <template v-if="homeData.acf.show_tournament_slider == true ">
        <Gallery
          :Tourncode="course"
          :TournName="head"
          :develop="develop"
          :Year="this.season"
        />
      </template>
      <template v-else>
        <WinnerSlider
          :gallery_title="gallery_title"
        />
      </template>
    </div>


    <!-- Featured Content Section -->

    <!-- <div id="feature" class="mobileMarginFeatured featuredContact">
      <h2 class="SectionTitle container">Featured Content</h2>
      <Post :posts="featured" :develop="develop" :config="config" :featuredTrue="featuredTrue" />
    </div> -->

    <!-- OOM Section -->

    <div class="OOMSection mobileMargin oomMobileMargin">
        <SmallOom
          :develop="develop"
          :oom="oom"
          :season="this.season"
          :home="homepage"
          :key="componentKey"
          :config="config"
        />
      <b-button class="homeButton" :to="{
                        name: 'stats',
                        query: {
                          id: this.season,
                          oom:'Y'
                        },
                      }"
      
      >See Full Order of Merit</b-button>
    </div>

    <!-- Video Section -->

    <div class="VideoSection container">
      <h2 class="SectionTitle">Latest Videos</h2>
      <b-row>
        <b-col lg='8' sm="12">
          <div v-html="homeData.acf.video_left"></div>
          <p class="youtubeTitle" v-html="homeData.acf.video_title"></p>
        </b-col>
        <b-col lg='4' sm="12">
          <b-link v-bind:href="homeData.acf.youtube_image_url_right" target="_blank">
            <template v-if='isMobile(true)'>
            <img class="YoutubeLink" src="https://assets.asiantour.com/asian-tour/2024/05/YouTube_Mobile.png">
            </template>
            <template v-else>
              <img class="YoutubeLink" :src="homeData.acf.youtube_image_right">
            </template>
          </b-link>
        </b-col>
      </b-row>
      <b-button class="homeButton" to="/video">See All Videos</b-button>
    </div>

    <!-- Destinations Section -->
    <div class="distinationsSection">
      <div class="Greengrad"></div>
      <template v-if='isMobile(true)'>
        <b-card
          :img-src="homeData.acf.destinations_image"
          img-alt="Image"
          img-top
          tag="article"
          class="DistCard mobileDistCard"
        >
          <b-card-text>
            <div class="cardPad mobileCardPad">
              <h2 class="distTitle" v-html="homeData.acf.destinations_title"></h2>
              <p class="distPara" v-html="homeData.acf.destinations_description"></p>
              <b-button class="distButton" to="/destinations">Discover Destinations</b-button>
            </div>
          </b-card-text>
        </b-card>
      </template>
      <template v-else>
        <b-card :img-src="homeData.acf.destinations_image" img-alt="Card image" img-left class="DistCard">
          <b-card-text>
            <div class="cardPad">
              <h2 class="distTitle" v-html="homeData.acf.destinations_title"></h2>
              <p class="distPara" v-html="homeData.acf.destinations_description"></p>
              <b-button class="distButton" to="/destinations">Discover Destinations</b-button>
            </div>
          </b-card-text>
        </b-card>
      </template>
    </div>

    <!-- Social Wall Section -->

    <div class="socialwall">
      <!-- <div v-html="homeData.acf.social_wall_block"></div> -->

      <!-- <iframe src="https://wp-asiantour.ocs-sport.com/wp-content/themes/asian-tour/templates/socialwall.html"></iframe> -->
      <!-- <iframe src="https://wp-asiantour.ocs-sport.com/wp-content/themes/asian-tour/templates/spon.php"></iframe> -->
    </div>
    <!-- <div id="sponsors">
      <div class="gradSponsor"></div>
      <Sponsors :sponsor="sponsor" :develop="develop" />
    </div> -->
  </div>
</template>

<script>
import axios from "axios"
import AdBanner from "@/components/adBanner.vue";
import Slider from "@/components/slider.vue";
// import MiniLeaderboard from "@/components/miniLeaderboard.vue"
import UpComing from "@/components/upcoming.vue";
// import UpComingTest from "@/components/upcomingTest.vue";
import NextTournament from "@/components/nextTournament.vue";
import SmallOom from "@/components/smallOom.vue";
// import SmallOomRight from "@/components/oomRightSlider.vue";
// import Video from "@/components/videoHome.vue";
import Gallery from "@/components/galleryHome.vue";
import WinnerSlider from "@/components/winnerslider.vue";
// import Sponsors from "@/components/sponsors.vue";
import Post from "@/components/posts.vue";
import apiCall from "@/services/api.js";
export default {
  name: "home",
  props: ["develop", "season", "oom", "config", "currentReport", "title"],
  components: {
    AdBanner,
    Slider,
    UpComing,
    NextTournament,
    WinnerSlider,
    // SmallOomRight,
    // UpComingTest,
    // MiniLeaderboard,
    SmallOom,
    // Video,
    Post,
    Gallery,
    // Sponsors,
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      data:[],
      description: [], //Meta Description
      homepage: true,
      homeData: [],
      wpHome:
        this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/pages/21?_embed&randomadd=" + new Date().getTime(),
      SliderPost: this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/posts?_embed?per_page=1",
      posts: this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/posts?_embed?per_page=10" + "&randomadd=" + new Date().getTime(),
      featured: this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/posts?categories=12",
      sponsor: this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/",
      dest:
        this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/posts?categories=77",
      componentKey: 0,
      tm_params: [],
      reports: [],
      course: [],
      pastT: [],
      DEFIMAGESAMS3: [],
      live_scoring: '',
      Newreport: '',
      match: '',
      Stableford: '',
      featuredTrue: 'Y',
      nextRound: '',
      roundBtns:[],
      gallery_title: ''
    };
  },
  computed: {
    // currentReport() {
    //   if (Array.isArray(this.reports))
    //     if (this.reports[this.reports.length - 1].report_title === 'Live Scoring Monitor' || this.reports[this.reports.length - 1].report_title === 'Course Statistics' || this.reports[this.reports.length - 1].report_title === 'Course Statistics (SF)' || this.reports[this.reports.length - 1].report_title === 'Course Statistics (LV)'|| this.reports[this.reports.length - 1].report_title === 'Scoring Statistics' || this.reports[this.reports.length - 1].report_title === 'Statistics Tables') {
    //       if (this.tm_params.rounds_played === '1') {
    //         return this.reports[this.reports.length - 5].report_url;
    //       } else {
    //        console.log('Line 284')
    //        if (this.reports[this.reports.length - 1].report_title === 'Course Statistics' || this.reports[this.reports.length - 1].report_title === 'Course Statistics (LV)' || this.reports[this.reports.length - 1].report_title === 'Course Statistics (SF)') {
    //           return this.reports[this.reports.length - 5].report_url; 
    //        } else {
    //           return this.reports[this.reports.length - 1].report_url; 
    //        }
    //       }
    //     } else {
    //       console.log('Line  288')
    //       return this.reports[this.reports.length - 1].report_url;
    //     }
    //     else return this.reports.report_url;
    // },
    // currentReport: function() {
    //   if (Array.isArray(this.reports))
    //     if (this.reports[this.reports.length - 1].report_title === 'Live Scoring Monitor' || this.reports[this.reports.length - 1].report_title === 'Course Statistics' || this.reports[this.reports.length - 1].report_title === 'Scoring Statistics' || this.reports[this.reports.length - 1].report_title === 'Statistics Tables') {
    //       if (this.tm_params.rounds_played === '1') {
    //         return this.reports[this.reports.length - 5].report_url;
    //       } else {
    //         return this.reports[this.reports.length - 4].report_url; 
    //       }
    //     } else {
    //       return this.reports[this.reports.length - 1].report_url;
    //     }
    //   else return this.reports.report_url;
    // },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_APIFULL_URL +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entryW-entries.json?randomadd=" +
          new Date().getTime();
      } else if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_APIFULL_URL +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscores") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.tm_params.tm_params.season_code +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.tm_params.tm_params.season_code +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1 ) {
          {
            url =
              this.config.VUE_APP_APIFULL_URL +
              this.tm_params.tm_params.season_code +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.tm_params.tm_params.season_code +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          // console.log("resNum === ");
          // console.log(resNum1);
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.tm_params.tm_params.season_code +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_APIFULL_URL +
            this.tm_params.tm_params.season_code +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
    showReport: function() {
      apiCall
      .report(this.reportTitle)
      .then(response => {
        this.Newreport = this.reportTitle
        this.data = response.data;
        // console.log("this.data show Report")
        // console.log(this.data)
				// for (let r = 1; r <= 16; r++) {
				// 	const rndStatus = this.data['ts_round_status_' + r];

        //   if (rndStatus == 'D') {
        //     this.nextRound = parseInt(response.data.rounds_played)
        //   } else {
        //     this.nextRound = parseInt(response.data.rounds_played) + 1 
        //   }
				// }
        this.nextRound = response.data.round;
        // this.lastroundComplete = response.data.last_round_complete;
        // if (this.lastroundComplete) {
        //   this.nextRound = parseInt(response.data.rounds_played) + 1 
        // } else {
        //    this.nextRound = parseInt(response.data.rounds_played)
        // }
        // console.log("this.data home")
        // console.log(this.data)
        this.loading = false;
      })
    },
    updateStuff: function() {
      apiCall
        .report(this.reportTitle)
        .then(({ data }) => {
          this.data = data;
          // this.showReport();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
			getLatestDraw(data) {
				const reportsList = this.reports;

				let currDrawRnd = 0;
				// let roundCount = 0;
				let lastRnd = 0;

				this.roundBtns = [];

				for (const i of reportsList) {
					let rndNo = 1;

					if (i.report_title.includes('Draw')) {
						if (i.report_title.includes(' '))
							rndNo = parseInt(i.report_title.split(' ')[1]);

						this.roundBtns.push({'label': 'Rnd ' + rndNo, 'round': rndNo});
						lastRnd = rndNo;
					}
				}

				for (let r = 1; r <= 16; r++) {
					const rndStatus = data['ts_round_status_' + r];

					// if (rndStatus.length) {
						// roundCount++;

						if (rndStatus != 'C' && rndStatus != 'S') {
							for (const i of reportsList) {
								if (i.report_title.includes('Draw') && i.report_title.includes('Round ' + r))
									currDrawRnd = r;
							}

							break;
						}
					// }
				}

				if (currDrawRnd == 0)
					currDrawRnd = lastRnd;
					// currDrawRnd = roundCount;

				this.currRound = currDrawRnd;
      }
  },
  mounted() {
    axios
      .get(this.config.VUE_APP_WP_ASIAN_TOUR_API_URL + "wp/v2/pages/21?_embed&randomadd=" + new Date().getTime())
      .then(response => {
        this.homeData = response.data;
        this.gallery_title = response.data.acf.gallery_title
        this.showReport();
        this.getLatestDraw();
        this.roundButtons();
        this.loading = true;
      });
  },
  created() {
    apiCall 
      .tmParamsBase()
      .then(({ data }) => {
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        // console.log( "this.DEFIMAGESAMS3 428" )
        // console.log( this.DEFIMAGESAMS3 )
        this.tm_params = data;
        this.match = data.match_play;
        this.Stableford = data.stableford;
        this.live_scoring = data.live_scoring;
        this.multi_tourns = data.multi_tourns;
        if (this.multi_tourns == 'Y') {
         this.pastT = data.past_tourns.past_tourns_entry.code; 
        }
        if (Array.isArray(data.past_tourns.past_tourns_entry)) {
          // console.log("isArray");
          this.past_tourns = data.past_tourns.past_tourns_entry;
        } else {
          // console.log("is not Array");
          this.past_tourns = [data.past_tourns.past_tourns_entry];
        }
        this.tm = data;
        this.head = data.full_name;
        this.course = data.code;
        this.okfound = data.ok_found;
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.loading = false;
        // this.showReport();
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
      setInterval(this.updateStuff, 120000);
  },
};
</script>
<style scoped>
::v-deep .newsImage {
  width: 100%;
  padding-right: 5px;
  min-height: 233px;
}
.mobileMarginFeatured.featuredContact  {
  margin-top: 50px;
}
::v-deep .OOMSection.mobileMargin.oomMobileMargin {
  margin-top: 60px;
}
.adHeightHome {
  height: 125px;
}
::v-deep .gradSponsor {
  background-color: #D2682E;
  background-image: linear-gradient(to right, #ffb21c, #D2682E);
  height: 10px;
  width: 100%;
}
::v-deep .Greengrad {
  background-color: #98C556;
  background-image: linear-gradient(to right, #98C556 , #BCDD8D);
  height: 10px;
  width: 100%;
  margin-top: 0px;
}
article.card.DistCard.mobileDistCard > img{
  border-radius: 0!important;
}
.DistCard {
  border: 0px;
}
.mobileDistCard {
  border-radius: 0;
  border: 0px!important;
}
.mobileDistCard > .card-body {
  background-color: #042F62;
  color: #fff;;
}
.Post_WidgetSection {
  /* margin-bottom: 30px; */
  margin-bottom: 0px;
}
.NewsBTN {
  margin-bottom: 50px!important;
}
.GreyBack {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(https://assets.asiantour.com/asian-tour/2024/09/Latest-Images_Background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
::v-deep .slider {
  padding-bottom: 0!important;
}
.homeButton {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff!important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #D2682E;
  background-image: linear-gradient(#ffb21c, #D2682E);
  margin: auto;
  display: block;
  margin-top: 30px;
  font-size: 14px;
}
.homeButton:hover {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff!important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #41a2f6!important;
  background-image: none;
  margin: auto;
  display: block;
  margin-top: 30px;
  font-size: 14px;
}
::v-deep iframe {
  width: 100%!important;
  height: 395px;
}
p.youtubeTitle {
  font-size: 16px;
  font-weight: 500;
  color: #0A3F7F;
  margin-top: 10px;
}
.VideoSection {
  padding-top: 50px;
  padding-bottom: 50px;
}
.YoutubeLink {
  width: 100%;
}
.SectionTitle {
  font-size: 23px;
  font-weight: 500;
  color: #042F62;
  padding-bottom: 25px;
}
.cardPad {
  /* width: 400px; */
  width: 450px;
}
.distButton {
  background-color: #98C556;
  border-radius: 0px;
  width: 279px;
  height: 58px;
  font-weight: 500;
  color: #fff!important;
  line-height: 2.8;
  text-transform: uppercase;
  border: 0px;
}
.distButton:hover {
  background-color: #41a2f6;
  border-radius: 0px;
  width: 279px;
  height: 58px;
  font-weight: 500;
  color: #fff!important;
  line-height: 2.8;
  text-transform: uppercase;
  border: 0px;
}
.distTitle {
  font-size: 23px;
  font-weight: 500;
  padding-bottom: 30px;
}
.distPara {
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 20px;
}
::v-deep .distPara > p > strong {
  font-weight: 500;
}
.card.DistCard.flex-row > .card-body{
  background-color: #042F62;
  color: #fff;
  padding: 100px;
}
img.card-img-left {
  width: 50%;
}
img.imageReview {
  width: 100%;
}
.reviewSection.container {
  /* padding-top: 50px; */
  padding-bottom: 50px;
}
@media only screen and (max-width: 990px) {
  .cardPad {
    /* width: 400px; */
    width: auto;
  }
  ::v-deep .slider {
    padding-bottom: 0!important;
    /* margin-top: 110px; */
    margin-top: 10px;
  }
  .adHeightHome {
    height: auto!important;
  }
  .Post_WidgetSection {
    margin-bottom: 30px;
    margin-top: -50px;
  }
  .reviewSection.container {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .firstReview {
    margin-bottom: 10px;
  }
  .reviewBlock {
    display: block;
  }
  .home {
    background-color: #fff;
    margin-top: 25px;
    overflow: hidden;
  }
  .card.DistCard.flex-row {
    display: inline-block;
  }
  img.card-img-left {
    width: 100%;
  }
  ::v-deep #upComing {
    padding-top: 15px!important;
    padding-bottom: 50px;
  }
  ::v-deep #gallery {
    padding-top: 30px!important;
    padding-bottom: 50px;
  }
  ::v-deep .card-img-top {
    width: 100%;
    height: auto;
    min-height: 183px;
    border-radius: 10px 10px 0 0 !important;
    max-height: 100%;
  }
  ::v-deep .homeFeaturedContent {
    max-width: 100% !important;
    margin-bottom: 2em !important;
  }
  .mobSocial {
    display: block;
    width: 100%;
    min-width: 100%;
  }
  ::v-deep .mobileMarginFeatured {
    margin-top: 0px;
  }
  ::v-deep .mobileMargin {
    margin-top: -4em;
  }
  ::v-deep .oomMobileMargin {
    margin-top: 0em;
  }
  ::v-deep .mobileMarginDest {
    margin-top: -60px;
  }
  ::v-deep iframe {
    width: 100%!important;
    height: 250px;
  }
  ::v-deep div#oomSlider {
    margin-top: 22em!important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 1000px){
  ::v-deep .bkimage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px!important;
    background-position: center;
  }
  ::v-deep div#oomSlider {
    margin-top: 7em!important;
  }
}
@media only screen and (max-width: 1024px) {
  .GreyBack {
    padding-top: 20px;
    padding-bottom: 30px;
    /* padding-bottom: 50px;
    background-image: url(https://assets.asiantour.com/asian-tour/2024/09/Latest-Images_Background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  }
  .oomHeader {
    padding-bottom: 110px;
    padding-top: 30px;
  }
  .hidelatestNews {
    display: none;
  }
  ::v-deep .bkimage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    background-position: center;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep div#oomSlider {
    margin-top: 17em!important;
  }
}
@media only screen and (max-width: 425px) {
  .mobileCardPad {
    width: 350px;
  }
  div#ThreeNewsPics {
    display: none;
  }
  ::v-deep div#oomSlider {
    margin-top: 25em!important;
  }
}

::v-deep .carousel-control-prev {
  /* display: none; */
}
::v-deep .carousel-control-next {
  /* display: none; */
}
.instaLogo {
  max-width: 55px;
}
.socialTitle {
  font-size: 24px;
}
.home {
  background-color: #fff;
  margin-top: 25px;
}
#upComing {
  padding-top: 50px;
  padding-bottom: 50px;
}
#gallery {
  padding-top: 50px;
  padding-bottom: 50px;
}
#social {
  padding-bottom: 5%;
  margin-top: 2em;
}
::v-deep .nav-item {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .col-md-10 {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .fa-instagram {
  color: #cc4666;
}
.title {
  color: #133f7b;
}
.nav-link {
  display: block;
  padding: 0rem 0rem !important;
}
.mobSocial > h2 {
  padding-bottom: 20px;
}
</style>
